<template>
  <div class="me">
    <div>
      <div
        class="no-select action-name"
        v-on:click.stop.prevent="toggle"
        :class="{clicable: canExpand}"
      >
        <div class="text-primary">
          <i
            class="fa fa-rocket"
            style="vertical-align: middle; opacity: 0.6"
          ></i>
          <span style="margin-left: 3px">{{
            $t(`synoptic.events.actions.${action.title}`).toUpperCase()
          }}</span>
        </div>
      </div>
      <div class="pull-right">
        <span
          class="btn btn-xs"
          v-on:click.stop.prevent="$emit('delAction', index)"
        >
          <i class="fa fa-trash"></i>
        </span>
        <span
          class="btn btn-xs"
          v-on:click.stop.prevent="toggle"
          :disabled="!canExpand"
        >
          <i :class="collapsed ? 'fa fa-angle-down' : 'fa fa-angle-up'"></i>
        </span>
      </div>
    </div>
    <div class="action-form-container" v-if="!collapsed && canExpand">
      <div class="form-group form-group-sm">
        <template v-for="(field, ix) in fields">
          <ControlDataSelector
            :key="ix"
            v-if="field.name == 'data_id'"
            :value="field.value"
            :allowedTypes="['bool', 'float', 'int', 'string']"
            :connectorId="refConnectorId"
            @input="setFieldValue(field, $event)"
          />

          <ImageSelection
            v-else-if="field.type == 'image'"
            :key="ix"
            @input="setFieldValue(field, $event)"
            :value="field.value"
          />
          <ColorPicker
            v-else-if="field.type == 'color'"
            :key="ix"
            @input="setFieldValue(field, $event)"
            :value="field.value"
            :pickerStyle="{left: '0px'}"
            icon="background"
          />
          <v-select
            v-else-if="field.options"
            :key="ix"
            :value="field.value"
            :options="autocompleteList(field)"
            :label="field.label"
            :placeholder="$tc(field.placeholder)"
            :reduce="(option) => option[field.key]"
            @input="setFieldValue(field, $event)"
          />
          <label
            class="checkbox-inline checkbox-inline-bottom-margin no-select"
            v-else-if="isBoolean(field)"
            :key="ix"
            ><input
              type="checkbox"
              :checked="field.value"
              @change="setFieldValue(field, $event.target.checked)"
            />
            {{ $t(field.label) }}
          </label>
          <div :key="ix" class="form-group" v-else>
            <label for="" v-if="field.label">{{ $t(field.label) }}</label>
            <input
              class="form-control"
              :type="field.hidden ? 'hidden' : 'text'"
              :placeholder="$t(field.placeholder || field)"
              :value="field.value"
              :disabled="!isEnabled(field)"
              @input="setFieldValue(field, $event.target.value)"
            />
          </div>
        </template>
      </div>
      <div v-if="actionParams">
        <TogglePanel
          class="parameters"
          :title="$tc('parameter', 2)"
          :hint="$tc('hints.screen_parameters')"
          :collapsed="false"
          @close="onFormKeyValueClosed"
        >
          <AnimationForm
            class="parameters-content"
            v-if="actionType == 'style:animation'"
            v-model="animationParams"
          />
          <template v-else-if="actionParamsReady">
            <div class="form-group form-group-sm parameters-content">
              <div
                v-if="isDeviceSelectionApplicable"
                class="clicable"
                @click.stop.prevent="showDeviceSelection = !showDeviceSelection"
              >
                <span v-if="showDeviceSelection">
                  {{ $tc("connector", 1) }}
                  <i
                    class="fa fa-chevron-right"
                    style="color: #999; font-size: 80%"
                  ></i>
                </span>
                <span> {{ $tc("device", 1) }}s</span>
                <ToolTip :title="$tc('hints.add_device_as_screen_parameter')" />
                <div class="pull-right">
                  <span
                    :class="
                      showDeviceSelection
                        ? 'fa fa fa-angle-down'
                        : 'fa fa fa-angle-right'
                    "
                  ></span>
                </div>
              </div>
              <template v-if="showDeviceSelection">
                <div>
                  <v-select
                    v-model="deviceConnectorId"
                    :options="connectorList"
                    :reduce="(option) => option.id"
                    label="name"
                  />
                </div>
                <div class="input-group">
                  <select
                    class="form-control"
                    v-model="selectedDevice"
                    style="padding: 5px"
                    :disabled="!deviceList.length"
                  >
                    <option :value="null">{{ $t("select") }}</option>
                    <option
                      :value="item"
                      v-for="item in deviceList"
                      :key="item.id"
                    >
                      {{ item.name }}
                    </option>
                  </select>
                  <div class="input-group-btn" style="padding: 0; margin: 0">
                    <button
                      class="btn btn-sm"
                      style="height: 30px"
                      @click.stop.prevent="addDeviceParameter"
                      :disabled="!this.selectedDevice"
                      :class="
                        this.selectedDevice ? 'btn-primary' : 'btn-default'
                      "
                    >
                      {{ $t("confirm") }}
                    </button>
                  </div>
                </div>
              </template>
            </div>
            <FormKeyValue
              class="parameters-content"
              v-model="publicParams"
              ref="keyValueForm"
              :customizable="
                'custom_params' in action ? action.custom_params : true
              "
              :defaultSelection="false"
              :noFocus="true"
              keyColumn="key"
              keyText="insert_key"
              valueColumn="value"
              valueText="insert_value"
            />
          </template>
        </TogglePanel>
        <div
          v-if="
            (!showDeviceSelection && selectedScreenId) ||
              actionType == 'onlinee:machine_dashboard'
          "
          style="margin: 10px 5px"
        >
          <div>
            <div class="form-group form-group-sm">
              <div for="" style="font-size: 12pt">
                {{ $tc("connector", 1) }}
                <ToolTip :title="$tc('hints.select_a_screen_connector', 2)" />
              </div>
              <v-select
                v-model="targetConnector"
                :options="connectorList"
                :reduce="(option) => option.id"
                label="name"
              />
            </div>
          </div>
          <SynopticCustomDataSetter
            v-if="!hasDeviceId && actionType != 'onlinee:machine_dashboard'"
            v-model="synopticControlDataMap"
            :screenId="selectedScreenId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormKeyValue from "@/components/form-key-value.vue";
import ImageSelection from "@/components/synoptic/property-editor/controls/image-selection";
import ColorPicker from "@/components/editor/color-picker.vue";
import Panels from "@/assets/dashboard/panels.json";
import Actions from "@/assets/dashboard/actions.json";
import SynopticCustomDataSetter from "@/components/editor/synoptic-custom-data-setter.vue";
import ControlDataSelector from "@/components/synoptic/property-editor/controls/control-data-selector.vue";
import ToolTip from "@/components/tooltip.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import AnimationForm from "@/components/editor/animation-form.vue";
import {panelPosition} from "@/services/dashboard.js";
export default {
  name: "ControlEventActionForm",
  props: {
    eventName: {
      type: String,
      required: true
    },
    action: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    open: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    FormKeyValue,
    ImageSelection,
    ColorPicker,
    SynopticCustomDataSetter,
    ControlDataSelector,
    TogglePanel,
    ToolTip,
    AnimationForm
  },
  data() {
    return {
      collapsed: true,
      selectedDeviceConnectorId: null,
      selectedDevice: null,
      iShowDeviceSelection: false,
      actionParamsReady: true
    };
  },
  computed: {
    fields() {
      let lst = [];
      for (let name in this.action.options) {
        if (name != "params") {
          let field = JSON.parse(
            JSON.stringify(
              Actions.find((action) => action.type == this.action.type).options[
                name
              ]
            )
          );
          field.name = name;
          field.value = this.action.options[name].value;
          lst.push(field);
        }
      }
      return lst.sort((a, b) => {
        if (a.index > b.index) return 1;
        if (b.index > a.index) return -1;
        return 0;
      });
    },
    actionParams: {
      set(value) {
        this.$set(this.action.options, "params", value);
        this.$emit("change", this.index);
      },
      get() {
        return (this?.action?.options || {})?.params || null;
      }
    },
    actionType() {
      return this?.action?.type || "";
    },
    publicParams: {
      set(text_list) {
        this.params("public", text_list);
      },
      get() {
        return this.params("public");
      }
    },
    animationParams: {
      set(text_list) {
        let lst =
          text_list === ""
            ? []
            : (text_list || "")
                .split(".")
                .map((id) => ({id: id, text: "class"}));
        let entry = {
          name: "style:animation",
          items: lst
        };
        this.params("public", entry);
        // let entry = text_list === "" ? null : {};
        // if (entry) {
        //   (text_list || "").split(".").forEach((p) => (entry[p] = "class"));
        // }
        // this.actionParams = entry;
      },
      get() {
        return (this.params("public")?.items || [])
          .filter((i) => i.text == "class")
          .map((i) => i.id)
          .join(".");
        // return this.actionParams
        //   ? Object.keys(this.actionParams).join(".")
        //   : "";
      }
    },
    synopticControlDataMap: {
      set(values) {
        let entry = JSON.parse(JSON.stringify(this.params("private")));
        entry.items = (values.items || []).map((item) => ({
          id: `_${item.id}`,
          text: item.text
        }));
        this.params("private", entry);
      },
      get() {
        let entry = JSON.parse(JSON.stringify(this.params("private")));
        entry.items = (entry?.items || []).map((item) => ({
          id: item.id.replace(/^_/, ""),
          text: item.text
        }));
        return entry;
      }
    },
    selectedScreenId() {
      return (this.fields.find(({name}) => name == "screen_id") || {}).value;
    },
    targetConnector: {
      set(value) {
        let params = this.actionParams || {};
        if (value) {
          this.$set(params, "_connector_id", value);
        } else {
          this.$delete(params, "_connector_id");
        }
      },
      get() {
        return (this.actionParams || {})["_connector_id"] || "";
      }
    },
    connectorList() {
      let label = !this.iShowDeviceSelection
        ? "keep_selected"
        : !this?.refMap?.conn1
        ? "select_a_connector"
        : "keep_selected";
      return (this.$store.getters.equipmentList || [])
        .filter(({base_model}) => this.showDeviceSelection || !base_model)
        .map((item) => ({
          id: item.id,
          name: item.name
        }))
        .concat({
          id: "",
          name: ` ${this.$t(label)}`
        })
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        });
    },
    isDeviceSelectionApplicable() {
      const aType = this?.action?.type || "";
      return (
        ["style", "form", "user", "onlinee"].indexOf(aType.split(":")[0]) == -1
      );
    },
    showDeviceSelection: {
      set(value) {
        this.iShowDeviceSelection = value;
        if (this.iShowDeviceSelection && this.hasDeviceId) {
          let path = ((this.actionParams || {})?.device_id).replace(
            /(^\$\(['"]|['"]\).*$)/g,
            ""
          );
          let device = path ? this.$root.$formatter.findResource(path) : null;
          if (device) {
            this.selectedDevice = device;
            this.selectedDeviceConnectorId = device.connector_id;
          }
        }
      },
      get() {
        return this.iShowDeviceSelection;
      }
    },
    deviceList() {
      return this.deviceConnectorId
        ? this.$store.getters["dashboard/deviceList"].filter(
            ({connector_id}) =>
              parseInt(connector_id) == parseInt(this.deviceConnectorId)
          )
        : [];
    },
    deviceConnectorId: {
      set(value) {
        this.selectedDeviceConnectorId = value;
      },
      get() {
        return this.selectedDeviceConnectorId || this?.refMap?.conn1 || "";
      }
    },
    canExpand() {
      return this.fields.length || this.actionParams;
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    screenId() {
      return this?.draft?.screenId ? parseInt(this?.draft?.screenId) : "";
    },
    refMap() {
      return this.$store.getters["dashboard/screenRefMap"](this.screenId);
    },
    hasDeviceId() {
      return (this.actionParams || {})?.device_id ? true : false;
    },
    refConnectorId() {
      return this?.refMap?.conn1 || null;
    }
  },
  watch: {
    open: {
      handler(n) {
        this.collapsed = !n;
      },
      immediate: true
    },
    collapsed(n) {
      this.$emit(n ? "collapse" : "expand");
    }
  },
  methods: {
    setFieldValue(field, value) {
      if (field.name) {
        this.action.options[field.name].value = value;
        if (field.name == "data_id") {
          this.action.options[field.name].data_id = value;
        }
        this.$emit("change", this.index);
      }
    },
    autocompleteList(field) {
      let ori,
        tit,
        pos,
        lst = null;
      let optionsList = ["screens", "dataList", "connectorList"];
      if (optionsList.includes(field.options)) {
        lst = this.$store.getters["dashboard/" + field.options] || [];
        if (field.options == "screens" && /activated$/.test(this.eventName)) {
          lst = lst.filter(({id}) => parseInt(id) != parseInt(this.screenId));
        }
      } else if (field.options == "panels") {
        let src = Panels || [];
        lst = (this?.draft?.template?.panels || null || [])
          .filter((p) => p.template != "EquipmentEmptyPanel")
          .map((p) => {
            ori = src.find((s) => s.template.template == p.template);
            tit = this.$t(`synoptic.panels.${ori?.title || p.title}`);
            pos = panelPosition(this?.draft?.template, p.name);
            pos.row = `${pos.row + 1}`;
            pos.col = `${pos.col + 1}`;
            return {
              id: p.name,
              name: `${tit} @${pos.row},${pos.col}`,
              ix: `${pos.row.padStart(3, 0)},${pos.col.padStart(3, 0)}`,
              pos: `${pos.row},${pos.col}`
            };
          })
          .sort((a, b) => (a.ix > b.ix ? 1 : b.ix > a.ix ? -1 : 0));
      }
      return lst;
    },
    isBoolean(field) {
      return typeof this.action.options[field.name].value == "boolean";
    },
    isEnabled(field) {
      switch (field.name) {
        case "data_index": {
          let dataId =
            this.fields.find(({name, value}) => "data_id" == name && value)
              ?.value ?? "";
          let data =
            (dataId &&
              this.$store.getters["dashboard/dataList"].find(
                ({id}) => parseInt(id) == parseInt(dataId)
              )) ||
            null;
          return data && data.memory_size > 1;
        }
        default: {
          return true;
        }
      }
    },
    params(scope, value) {
      const enabled = (scope, name) => {
        if (name.charAt(0) == "_" && scope == "public") return false;
        if (name.charAt(0) != "_" && scope == "private") return false;
        return true;
      };
      if (value !== undefined) {
        // set
        let update = false;
        let text_list = value || {};
        let deleted = {};
        let values = {};
        if ("items" in text_list) {
          (text_list.items || []).forEach((item) => {
            values[item.id] = item.text;
          });
        }
        let params = JSON.parse(JSON.stringify(this.actionParams));
        for (let name in params) {
          if (enabled(scope, name)) {
            if (name in values) {
              if (params[name] !== values[name]) {
                update = true;
                params[name] = values[name];
              }
              delete values[name];
            } else {
              update = true;
              deleted[name] = true;
            }
          }
        }
        // deleted ones
        for (var d in deleted) {
          delete params[d];
        }
        // new ones
        for (var n in values) {
          update = true;
          params[n] = values[n];
        }
        if (update) {
          this.actionParams = params;
        }
      } else {
        if (this.actionParams) {
          let lst = [];
          for (let name in this.actionParams) {
            if (enabled(scope, name)) {
              lst.push({id: name, text: this.actionParams[name]});
            }
          }
          return {
            name: this.action.type,
            items: lst
          };
        } else {
          return null;
        }
      }
    },
    toggle() {
      if (!this.canExpand) return;
      this.collapsed = !this.collapsed;
    },
    addDeviceParameter() {
      if (!this.selectedDevice) return;
      let connId = parseInt(this.selectedDevice.connector_id);
      let connector = this.$store.getters["dashboard/connectorList"].find(
        ({id}) => parseInt(id) == connId
      );
      if (
        connector.base_model ||
        (this?.refMap?.conn1 && connId == parseInt(this?.refMap?.conn1))
      ) {
        connId = ".";
      }
      let actionParams = JSON.parse(JSON.stringify(this.actionParams || {}));
      actionParams[
        "device_id"
      ] = `$('${connId}/${this.selectedDevice.reference_id}')?.id`;
      let items = Object.keys(actionParams).map((id) => ({
        id: id,
        text: actionParams[id],
        checked: false
      }));
      this.params("public", {
        name: "screen:open",
        items: items
      });
      this.targetConnector = connId == "." ? "" : connId;
      this.actionParamsReady = false;
      this.$nextTick(() => {
        this.actionParamsReady = true;
        this.showDeviceSelection = false;
        this.selectedDevice = null;
      });
    },
    onFormKeyValueClosed() {
      if (this.$refs.keyValueForm && this.$refs.keyValueForm.pending) {
        this.$refs.keyValueForm.add();
      }
    }
  }
};
</script>

<style scoped>
.me {
  /* height of content when collapsed */
  min-height: 1.4285em;
}
.btn-group-xs > .btn,
.btn-xs {
  padding: 2px;
  margin: 0 -5px 0 10px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.action-form-container {
  clear: both;
  padding-top: 10px;
  margin-bottom: 10px;
  /* padding-bottom: 10px; */
  /* border-bottom: 1px solid #acacac; */
  border-radius: 5px;
}

.action-form-container > .form-group {
  margin-bottom: 0;
}

.action-name {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  position: absolute;
  max-width: 190px;
  letter-spacing: -0.5pt;
  font-weight: 600;
  margin-top: 5px;
}

.checkbox-inline-bottom-margin {
  margin-bottom: 10px;
}
.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.parameters {
  padding: 0 10px;
  border: 1px solid lightgray;
  background-color: white;
  margin-top: -1px;
  z-index: 101;
}

.parameters-content {
  margin-top: 10px;
  padding: 0;
}
::v-deep .parameters .box-header {
  padding: 5px 10px 5px 10px;
  margin: 0 -15px;
  z-index: 0;
}

>>> {
  /* custom */
  --vs-dropdown-option-padding: 3px 10px 5px 10px;
  --vs-line-height: 2.2rem;
  --vs-font-size: 1.6rem;
}
</style>
