<template>
  <section>
    <table class="table table-condensed table-bordered table-responsive">
      <thead>
        <tr>
          <td style="width:5%;min-width:30px;max-width:40px">
            <div class="btn no-padding" v-if="customizable">
              <input
                type="checkbox"
                v-model="all"
                v-bind:style="{
                  visibility: !entry.items.length ? 'hidden' : 'initial'
                }"
              />
            </div>
          </td>
          <td>
            <strong>{{ $tc(keyColumn) }}</strong>
          </td>
          <td>
            <strong>{{ $t(valueColumn) }}</strong>
          </td>
          <td style="width:5%;min-width:30px;max-width:40px">
            <div
              class="btn btn-xs"
              v-if="checked_list.length"
              v-on:click.stop.prevent="del"
              :title="$t('remove_selected_ones')"
            >
              <i class="fa fa-trash toolbar-button"></i>
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, ix) in entry.items" v-bind:key="ix">
          <td>
            <div class="btn no-padding" v-if="customizable">
              <input type="checkbox" v-model="item.checked" />
            </div>
          </td>
          <td>
            <div class="form-group">
              <input
                class="form-control no-outline no-padding"
                v-model="item.id"
                v-bind:class="{'input-error': inError(ix)}"
              />
            </div>
          </td>
          <td>
            <div class="form-group">
              <input
                type="text"
                class="form-control no-outline no-padding"
                v-model="item.text"
                v-bind:class="{'input-error': inError(ix)}"
              />
            </div>
          </td>
          <td>
            <div
              v-if="defaultSelection"
              class="btn no-padding"
              v-on:click.stop.prevent="entry.default_id = item.id"
            >
              <i
                class="fa fa-check icon-default"
                v-bind:class="{
                  'text-success': entry.default_id == item.id,
                  'text-gray': entry.default_id != item.id
                }"
                v-bind:title="$t('set_as_default')"
              >
              </i>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div
              v-if="isNewItemValid"
              class="btn btn-xs"
              v-bind:disabled="!isNewItemValid"
              v-on:click.prevent.stop="add"
            >
              <i class="fa fa-plus"></i>
            </div>
          </td>
          <td>
            <div class="form-group">
              <input
                class="form-control no-outline"
                type="text"
                v-model="new_item.id"
                ref="new_id"
                :placeholder="`${$tc('value', 1)}`"
                @keydown.enter.prevent.stop="add"
                @blur="onLostFocus"
              />
            </div>
          </td>
          <td>
            <div class="form-group">
              <input
                class="form-control no-outline"
                type="text"
                v-model="new_item.text"
                :placeholder="`${$tc('value', 1)}`"
                @keydown.enter.prevent.stop="add"
                @keydown.tab.exact.prevent.stop="add"
                @blur="onLostFocus"
              />
            </div>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <div class="input-group new-item" v-if="customizable"></div>
  </section>
</template>

<script>
function defaultItem() {
  return {id: "", text: "", checked: false, error: false};
}

export default {
  name: "FormKeyValue",
  props: {
    value: {
      type: Object,
      default: () => null
    },
    defaultSelection: {
      type: Boolean,
      default: () => true
    },
    keyColumn: {
      type: String,
      default: () => "value",
      required: false
    },
    valueColumn: {
      type: String,
      default: () => "text",
      required: false
    },
    keyText: {
      type: String,
      default: () => "value",
      required: false
    },
    valueText: {
      type: String,
      default: () => "new_text_value",
      required: false
    },
    noFocus: {
      type: Boolean,
      default: () => false,
      required: false
    },
    customizable: {
      type: Boolean,
      default: () => true,
      required: false
    }
  },
  data() {
    return {
      busy: false,
      entry: null,
      all: false,
      new_item: defaultItem(),
      etag: "",
      id: "",
      pending: false
    };
  },
  computed: {
    isValid() {
      return (this.entry.name && this.entry.items.length) || false;
    },
    isNewItemValid() {
      return this.new_item.id && this.new_item.text;
    },
    checked_list() {
      return (this.entry.items || []).filter(function(i) {
        return i.checked;
      });
    },
    errors() {
      let ids = this.entry.items.map(function(i) {
        return i.id + "";
      });
      let inserted = {};
      let errors = {};
      let items = this.entry.items.concat([this.new_item]);
      for (var i in items) {
        var key = items[i].id + "";
        if (key in inserted) {
          errors[i] = inserted[key];
        } else {
          inserted[key] = i;
        }
      }
      return errors;
    },
    formData() {
      let self = this;
      let payload = null;
      if (this.entry) {
        if ((this?.entry?.items || []).length) {
          payload = JSON.parse(JSON.stringify(this.value)) || {}; // original
          let default_item = null;
          for (let i in this.entry.items) {
            if (this.entry.items[i].id == this.entry.default_id) {
              default_item = {};
              default_item[this.entry.items[i].id] = this.entry.items[i].text;
            }
          }
          payload.items = JSON.parse(JSON.stringify(this.entry.items));
        }
      }
      return payload;
    }
  },
  watch: {
    all(n) {
      let items = JSON.parse(JSON.stringify(this.entry.items || [])).map(
        function(i) {
          i.checked = n;
          return i;
        }
      );
      this.$set(this.entry, "items", items);
    },
    formData(n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.$emit("input", n);
        this.pending = false;
      }
    }
  },
  methods: {
    inError(position) {
      let errors = this.errors;
      for (var i in errors) {
        if (errors[i] == position) {
          return true;
        }
      }
      return false;
    },
    add() {
      this.pending = false;
      if (!this.isNewItemValid) return;
      this.entry.items.push({
        id: this.new_item.id,
        text: this.new_item.text,
        checked: false
      });
      if (this.entry.items.length == 1) {
        this.entry.default_id = this.new_item.id;
      }
      this.new_item = defaultItem();
      this.focus();
    },
    del() {
      let items = JSON.parse(JSON.stringify(this.entry.items || [])).filter(
        (i) => !i.checked
      );
      this.$set(this.entry, "items", items);
      if (!items.length) {
        this.all = false;
      }
      let default_lst = items.filter((i) => i.id == this.entry.default_id);
      if (!default_lst.length && items.length) {
        this.entry.default_id = items[0].id;
      } else {
        this.entry.default_id = "";
      }
      if (this.noFocus) return;
      this.focus();
    },
    focus() {
      this.$nextTick(() => {
        if (this.entry && !this.entry.name) {
          if (this?.$refs?.entry_name) this.$refs.entry_name.focus();
        } else {
          if (this?.$refs?.new_id) this.$refs.new_id.focus();
        }
      });
    },
    initData() {
      let entry = null;
      if (this.value) {
        entry = JSON.parse(JSON.stringify(this.value));
        for (var i in entry.items || []) {
          entry.items[i].checked = false;
        }
      } else {
        entry = {
          default_id: 0,
          id: 0,
          name: "",
          items: []
        };
      }
      this.$set(this, "new_item", defaultItem());
      this.$set(this, "entry", entry);
    },
    onLostFocus() {
      this.pending = this.new_item.id && this.new_item.text ? true : false;
    }
  },
  created() {
    this.initData();
  },
  mounted() {
    if (!this.noFocus) this.focus();
  }
};
</script>

<style scoped>
.form-group {
  width: 100%;
  margin: 0;
  padding: 0;
}
.toolbar-button {
  margin: 0 5px;
}
.fa-btn:hover {
  color: #3c8dbc;
  cursor: pointer;
}
.icon-edit {
  color: darkgrey;
  padding-left: 1px;
}
.input-error {
  color: tomato;
}

.table-condensed > thead > tr > td,
.table-condensed > thead > tr > th,
.table-condensed > tbody > tr > td {
  text-align: center;
  vertical-align: middle;
  padding: 0;
  font-size: 12px;
}

input.form-control {
  width: 100%;
  font-size: 12px;
  text-align: center;
  border: 0;
  padding: 0;
}

.no-outline:focus {
  outline: none !important;
  border-color: #d2d6de !important;
}

.new-item input::placeholder {
  font-size: 1.2rem;
}
</style>
