<template>
  <TogglePanel
    v-if="panel"
    title="toolbar"
    :icon="{
      before: 'fa fa-flash',
      collapse: 'fa-caret-square-o-up',
      expand: 'fa-caret-square-o-down'
    }"
    persistent="toggle_panel_events_form"
  >
    <LinkedPanelWarning v-if="isSyncEnabled" />
    <template v-else>
      <draggable
        v-model="panelToolbar"
        class="item"
        handle=".handle"
        @end="onDragEnd"
      >
        <div
          class="row event-item"
          v-for="(control, ixControl) in panelToolbar"
          :key="ixControl"
        >
          <div class="col-xs-12">
            <div
              class="control-toggle control-header"
              :class="control.collapsed ? 'btn-default' : 'btn-primary'"
            >
              <div
                class="clicable handle"
                v-on:click.stop.prevent="toggleControl(ixControl)"
              >
                <i class="fa fa-arrows-v move-icon"></i>
                <i class="control-icon" :class="control.icon"></i>
                {{ control.collapsed ? control.title : $tc("button") }}
              </div>
              <div class="control-header-buttons">
                <span
                  class="btn btn-xs"
                  v-on:click.stop.prevent="delPanelControl(ixControl)"
                >
                  <i class="glyphicon glyphicon-trash"></i>
                </span>
                <span
                  class="btn btn-xs"
                  v-on:click.stop.prevent="toggleControl(ixControl)"
                >
                  <i
                    class="glyphicon"
                    :class="
                      control.collapsed
                        ? 'glyphicon-collapse-down'
                        : 'glyphicon-collapse-up'
                    "
                  ></i>
                </span>
              </div>
            </div>

            <div
              class="form-group form-group-sm control-box"
              v-if="!control.collapsed"
            >
              <div class="input-group">
                <div class="input-group-addon btn input-group-addon-select">
                  <select
                    :value="control.contentType || 'icon'"
                    @change="
                      setControlContentType(ixControl, $event.target.value)
                    "
                  >
                    <option value="icon">{{ $tc("icon") }}</option>
                    <option value="title">{{ $t("title") }}</option>
                    <option value="both">{{ $t("both") }}</option>
                  </select>
                </div>
                <input
                  class="form-control"
                  type="text"
                  :value="control.title"
                  @input="setControlTitle(ixControl, $event.target.value)"
                />
                <div
                  class="input-group-addon btn btn-icon"
                  v-if="control.contentType != 'title'"
                  @click.stop.prevent="toggleIcon($event)"
                >
                  <IconLibrary
                    @input="setControlIcon(ixControl, $event)"
                    :value="control.icon"
                  >
                    <template #button="{toggle}">
                      <i :class="control.icon" @click.stop.prevent="toggle"></i>
                    </template>
                  </IconLibrary>
                </div>
              </div>
              <div
                v-for="(action, iAction) in control.on.click.actions"
                :key="iAction"
                style="clear: both"
              >
                <ControlEventActionForm
                  eventName="click"
                  :control="control"
                  :action="action"
                  :index="iAction"
                  :open="iAction === newActionIndex"
                  @delAction="onDelAction(ixControl, $event)"
                  @change="onActionChange(ixControl, $event)"
                />
              </div>
              <div style="padding: 5px 5px 0px 5px">
                <div class="form-group form-group-sm">
                  <label class="label-inline" for="editor-text-align"
                    >{{ $t("info") }}
                    <Tooltip
                      :title="
                        `${$t('hint')},
                    ${$t('description')}, ${$t('info')}`
                      "
                    />
                  </label>
                  <input
                    :placeholder="`${$t('text')}`"
                    class="form-control"
                    @input="controlHint(ixControl, $event.target.value)"
                    :value="controlHint(ixControl)"
                  />
                </div>
              </div>
              <ControlActionSelector
                style="padding: 5px;margin: -15px 0 -15px 0"
                eventName="click"
                :exclude="eventActions(ixControl)"
                :control="control"
                @addAction="addControlAction(ixControl, $event)"
              />
              <div style="padding: 5px 5px 10px">
                <AlertForm
                  @input="confirmation(ixControl, $event)"
                  :value="confirmation(ixControl)"
                />
                <ProcessAreaPermissions
                  :value="control"
                  @input="setControlPermission(ixControl, $event)"
                  tooltipNoun="button"
                  style="margin-bottom: 10px"
                />
              </div>
            </div>
          </div>
        </div>
      </draggable>
      <div class="new_button text-center" style="padding: 0px 0 20px 0">
        <button
          class="btn btn-sm btn-primary"
          @click.stop.prevent="addPanelControl"
          :disabled="isSyncEnabled"
        >
          {{ $t("new_button") }}
        </button>
      </div>
      <div class="row" style="padding: 10px 0 20px 0" v-if="nToolbarItems">
        <div class="col-xs-12">
          <div class="form-group form-group-sm customize-buttons">
            <label for="">{{ $t("customize_buttons") }}</label>

            <div class="input-group">
              <span class="input-group-addon" :title="$t('font_size')">
                <i class="fa fa-text-height"></i>
              </span>
              <InputNumber
                v-model="toolbarStyleFontSize"
                class="form-control text-center"
                :placeholder="$t('font_size')"
                :title="$t('font_size')"
              />
              <span class="input-group-addon">pt</span>
            </div>
          </div>
        </div>
        <div class="col-xs-12">
          <div class="form-group form-group-sm">
            <label for="">{{ $t("type") }}</label>
            <Radio
              id="toolbarType"
              :options="toolbarTypeOptions"
              v-model="toolbarType"
            />
          </div>
        </div>
      </div>
    </template>
  </TogglePanel>
</template>

<script>
import Events from "@/assets/dashboard/events.json";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlActionSelector from "@/components/editor/control-action-selector.vue";
import ControlEventActionForm from "@/components/editor/control-event-action-form.vue";
import IconLibrary from "@/components/editor/icon-library.vue";
import AlertForm from "@/components/editor/alert-form.vue";
import draggable from "vuedraggable";
import ProcessAreaPermissions from "@/components/process-area-permissions.vue";
import LinkedPanelWarning from "@/components/control-sidebar/property-editors/linked-panel-warning.vue";
import Radio from "@/components/base/radio.vue";
import Tooltip from "@/components/tooltip.vue";
import InputNumber from "@/components/input-number.vue";
import {isLinkedPanel, isSyncEnabled} from "@/services/dashboard.js";
import { onlyNumbers } from "@/plugins/utils.js";

export default {
  name: "PanelEventsForm",
  components: {
    TogglePanel,
    ControlActionSelector,
    ControlEventActionForm,
    IconLibrary,
    AlertForm,
    ProcessAreaPermissions,
    draggable,
    LinkedPanelWarning,
    Radio,
    Tooltip,
    InputNumber
  },
  data() {
    return {
      newActionIndex: -1,
      collapsed: {}
    };
  },
  computed: {
    panelToolbar: {
      set(value) {
        if (!this.panel) return;
        let panel = JSON.parse(JSON.stringify(this.panel || null));
        panel.toolbar = value;
        if (!panel?.toolbar?.length) {
          panel.toolbarType = "";
        }
        this.saveDraft(panel);
      },
      get() {
        return (this?.panel?.toolbar || []).map((control, ix) => {
          return {...control, collapsed: this.collapsed[ix] ?? true};
        });
      }
    },
    nToolbarItems() {
      return this?.panel?.toolbar?.length || 0;
    },
    toolbarStyleFontSize: {
      set(value) {
        let toolbarStyle = this.toolbarStyle;
        if (value == "") toolbarStyle['font-size'] = "initial";
        else toolbarStyle['font-size'] = value+'pt';
        this.toolbarStyle = toolbarStyle;
      },
      get() {
        return onlyNumbers(this.toolbarStyle['font-size'] ?? "");
      }
    },
    toolbarStyle: {
      set(value) {
        if (!this.panel) return;
        let panel = JSON.parse(JSON.stringify(this.panel || null));
        panel.toolbarStyle = value;
        this.saveDraft(panel);
      },
      get() {
        return this?.panel?.toolbarStyle ?? {};
      }
    },
    toolbarType: {
      set(value) {
        if (!this.panel) return;
        let panel = JSON.parse(JSON.stringify(this.panel || null));
        panel.toolbarType = value;
        this.saveDraft(panel);
      },
      get() {
        return this?.panel?.toolbarType ?? "";
      }
    },
    toolbarTypeOptions() {
      return [
        {title: this.$utils.proper(this.$t("automatic")), value: ""},
        {title: this.$utils.proper(this.$t("dropdown")), value: "dropdown"}
      ];
    },
    screenId() {
      return this.$store.getters["dashboard/draft"]?.screenId || null;
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    panelName() {
      return this?.panel?.name || "";
    },
    template() {
      return (this.$store.getters["dashboard/draft"] || {}).template || null;
    },
    isLinkedPanel() {
      return isLinkedPanel(this.template, this.panelName);
    },
    isSyncEnabled() {
      return isSyncEnabled(this.template, this.panelName);
    },
    hint: {
      set(value) {},
      get() {
        return "";
      }
    }
  },
  methods: {
    saveDraft(panel) {
      this.$store.dispatch("dashboard/saveDraftPanel", {
        screenId: this.screenId,
        panelName: panel.name,
        panel: panel,
        setAsCurrent: true
      });
    },
    addPanelControl() {
      if (this.isSyncEnabled) return;
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      toolbar.push({
        icon: "fa fa-flag",
        title: this.$t("title"),
        rule: "",
        on: {
          click: Events["click"]
        }
      });
      this.toggleControl(-1);
      this.$set(this.collapsed, toolbar.length - 1, false);
      this.panelToolbar = toolbar;

      // Força a reatividade do tamanho da fonte do botão
      const fontSize = this.toolbarStyleFontSize;
      this.$set(this, "toolbarStyleFontSize", "");
      this.$nextTick(() => this.$set(this, "toolbarStyleFontSize", fontSize));
    },
    delPanelControl(ixControl) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      toolbar.splice(ixControl, 1);
      this.panelToolbar = toolbar;
      delete this.collapsed[ixControl];
    },
    addControlAction(ixControl, action) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        let control = toolbar[ixControl];
        let controlEvents = control.on || {};
        controlEvents.click = control.on.click || Events["click"];
        controlEvents.click.actions.push(action);
        control.on = controlEvents;
        this.panelToolbar = toolbar;
        this.newActionIndex = controlEvents.click.actions.length - 1;
      }
    },
    onDelAction(ixControl, ixAction) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        let control = toolbar[ixControl];
        let controlEvents = control.on || {};
        controlEvents.click = control.on.click || Events["click"];
        if (ixAction < controlEvents.click.actions.length) {
          controlEvents.click.actions.splice(ixAction, 1);
          control.on = controlEvents;
          this.panelToolbar = toolbar;
        }
      }
      this.newActionIndex = -1;
    },
    controlHint(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl >= 0 && ixControl < toolbar.length) {
        if (value !== undefined) {
          toolbar[ixControl].hint = value;
          this.panelToolbar = toolbar;
        }
        return this.panelToolbar[ixControl].hint || "";
      }
      return "";
    },
    setControlTitle(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        toolbar[ixControl].title = value;
        this.panelToolbar = toolbar;
      }
    },
    setControlIcon(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        toolbar[ixControl].icon = value;
        this.panelToolbar = toolbar;
      }
    },
    setControlContentType(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        toolbar[ixControl].contentType = value;
        this.panelToolbar = toolbar;
      }
    },
    setControlPermission(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      toolbar[ixControl].view_permission = value.view_permission;
      toolbar[ixControl].interaction_permission = value.interaction_permission;
      this.panelToolbar = toolbar;
    },
    onActionChange(ixControl, ixAction) {
      this.panelToolbar = this.panelToolbar;
    },
    eventActions(ixControl) {
      let actions = [];
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        let control = toolbar[ixControl];
        if (control) {
          let evt = control?.on?.click || Events["click"] || null;
          if (evt) {
            actions = evt.actions.map((i) => i.type);
          }
        }
      }
      return actions;
    },
    confirmation(ixControl, value) {
      let toolbar = JSON.parse(JSON.stringify(this.panelToolbar));
      if (ixControl < toolbar.length) {
        let control = toolbar[ixControl];
        if (control) {
          let evt = control?.on?.click || Events["click"] || null;
          if (evt) {
            if (value) {
              evt.confirmation = value;
              this.panelToolbar = toolbar;
            }
            return evt.confirmation || null;
          }
        }
        return null;
      }
    },
    toggleIcon($event) {
      $event.target.children[0].click();
    },
    toggleControl(ix) {
      for (var i = 0; i < this.panelToolbar.length; i++) {
        this.$set(
          this.collapsed,
          i,
          parseInt(ix ?? -1) === i ? !this.collapsed[i] : true
        );
      }
    },
    onDragEnd($event) {
      if ($event.newIndex == $event.oldIndex) return;
      let state = this.collapsed[$event.oldIndex];
      this.toggleControl(-1);
      this.collapsed[$event.newIndex] = state;
    },
    openControlAt(ix) {
      this.toggleControl();
      this.toggleControl(ix);
    },
    onDrawerEvent($event) {
      if (!$event) return;
      switch ($event.action) {
        // app.__vue__.$root.$emit("drawer:event",{action:"control:open", details:{index:2}})
        case "control:open": {
          this.openControlAt($event?.details?.index ?? 0);
        }
      }
    }
  },
  created() {
    this.toggleControl(-1);
    this.$root.$on("drawer:event", this.onDrawerEvent);
  },
  beforeDestroy() {
    this.$root.$off("drawer:event", this.onDrawerEvent);
  }
};
</script>

<style scoped>
.btn-icon {
  min-width: 45px;
}
.new_button {
  margin-top: 10px;
}
.input-group-addon-select {
  padding: 0;
}
.input-group-addon-select > select {
  border: 0;
  font-size: 85%;
  background: #ffffff;
}
.input-group-addon-select > select:hover {
  cursor: pointer;
  opacity: 0.8;
}
.input-group-addon-select > select:focus {
  border: 0;
  outline-width: 0;
}
.control-header {
  position: relative;
  padding: 5px 2px;
  font-weight: 600;
  border-radius: 3px;
  margin-top: 1px;
}

.control-header.btn-primary {
  border-radius: 3px 3px 0 0;
}

.control-header-buttons {
  position: absolute;
  top: 5px;
  right: 0;
}
.control-box {
  margin: 0 0 10px 0;
  border-width: 0 0 1px 0;
  border-color: #ddd;
  border-style: solid;
  border-radius: 5px;
  box-shadow: 0px 0px 6px #ddd;
}
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
.control-icon {
  margin-left: 5px;
  min-width: 20px;
}
.move-icon {
  color: #999;
  margin-left: -10px;
  margin-right: 5px;
}

.event-item {
  padding-bottom: 10px;
}

.customize-buttons .input-group .input-group-addon:first-child {
  min-width: 50px;
}

.customize-buttons .input-group .input-group-addon:last-child {
  width: 40px;
}

.form-group-sm .input-group-addon {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
</style>
