<template>
  <Modal
    :title="$t('create_new_tab.title')"
    :open="open"
    @show="init"
    @hide="(propUpdate) => !propUpdate && $emit('update:open', false)"
    @hidden="closeIconLibrary"
    id="create-dashboard-tab-modal"
  >
    <template #content>
      <p>
        {{ $t("create_new_tab.description") }}
      </p>
      <div class="fields">
        <ScreenSelector
          v-model="screenId"
          :label="$tc('screen')"
          :except="exceptionIdList"
          v-if="screens.length"
        />
        <div class="form-group">
          <label for="tab-title"
            >{{ $t("title") }} ({{ $tc("optional", 1).toLowerCase() }})
            <Tooltip :title="$t('create_new_tab.title_hint')"
          /></label>
          <input
            type="text"
            class="form-control"
            id="tab-title"
            :placeholder="screen.name"
            v-model="tab.title"
            data-testid="title"
          />
        </div>
        <div class="form-group">
          <label>{{ $tc("icon") }}</label>
          <div>
            <button
              class="btn"
              :class="iconPanelOpen ? 'btn-primary' : 'btn-default'"
              @click.stop.prevent="iconPanelOpen = !iconPanelOpen"
            >
              <i :class="icon || 'fa fa-desktop'"></i>
            </button>
          </div>
        </div>
      </div>
      <div>
        <IconSelector v-if="iconPanelOpen" v-model="icon" />
      </div>
    </template>
    <template #footer>
      <BaseButton @click="$emit('update:open', false)" data-testid="close">{{
        $t("close")
      }}</BaseButton>
      <BaseButton
        type="primary"
        :disabled="loading"
        @click="save"
        data-testid="save"
        >{{ loading ? $t("loading") + "..." : $t("save") }}</BaseButton
      >
    </template>
  </Modal>
</template>

<script>
import Modal from "@/components/widgets/modal";
import Tooltip from "@/components/tooltip";
import BaseButton from "@/components/base/buttons/base-button";
import IconSelector from "@/components/editor/icon-selector.vue";
import ScreenSelector from "@/components/editor/screen-selector.vue";

import {mapActions, mapGetters} from "vuex";
import MixinAlert from "@/project/mixin-alert";

export default {
  name: "CreateDashboardTabModal",
  mixins: [MixinAlert],
  components: {
    Modal,
    Tooltip,
    BaseButton,
    IconSelector,
    ScreenSelector
  },
  props: {
    open: {
      type: Boolean
    },
    context: {
      type: String,
      default: "user",
      validator: (v) => ["user", "contract"].includes(v)
    }
  },
  data() {
    return {
      tab: {
        screenId: null,
        title: "",
        iconClass: "fa fa-desktop",
        name: "",
        href: "/dashboard/search/custom/",
        component: "DashboardCustom",
        enabled: true,
        rule: "TelaCustomizadaAcesso",
        contractId: null
      },
      loading: false,
      loadingScreens: false,
      iconPanelOpen: false,
      screensLoaded: false
    };
  },
  computed: {
    screen() {
      return (
        this.screens?.find?.((s) => s.id == this.tab.screenId) ?? {name: ""}
      );
    },
    iconName() {
      let name = "";
      if (this.tab.iconClass) {
        let icon = this.tab.iconClass.split(" ")[1];
        name = icon.substring(icon.indexOf("-") + 1);
      }
      return name;
    },
    existingTabs() {
      if (this.context == "user")
        return this.loggedUser.user_profile.portal_data?.userTabs ?? [];
      else return this.loggedUser.contract.portal_data?.userTabs ?? [];
    },
    availableScreens() {
      let lst = this.screens.filter(
        (s) =>
          !this.existingTabs.some(
            (t) => t.name.replace("@", "") == this.$utils.kebab(s.name)
          ) &&
          s.id != -1 &&
          !s.public
      );

      return lst;
    },
    exceptionIdList() {
      return (this.screens || [])
        .filter(
          (s) =>
            s.public ||
            (this.existingTabs || []).some(
              (t) =>
                this.$utils.asKey(s.name.replace("@", "")).toLowerCase() ==
                this.$utils.asKey(t.name).toLowerCase()
            )
        )
        .map(({id}) => parseInt(id));
    },
    updateService() {
      let services = {
        user: this.updateUserPortalData,
        contract: this.updateContractPortalData
      };
      return services[this.context];
    },
    icon: {
      set(value) {
        this.iconPanelOpen = false;
        this.tab.iconClass = value;
      },
      get() {
        return this.tab.iconClass;
      }
    },
    screenId: {
      set(value) {
        this.tab.screenId = parseInt(value);
      },
      get() {
        return this.tab.screenId || this.availableScreens.length
          ? this.availableScreens[0].id
          : "";
      }
    },
    ...mapGetters("dashboard", ["screens"]),
    ...mapGetters("user", ["contract_id", "loggedUser"])
  },
  methods: {
    save() {
      if (!this.tab.screenId) {
        if (this.$refs.screen) {
          this.$refs.screen.reportValidity();
        } else {
          this.$refs.noScreens.focus();
        }
      } else {
        this.tab.title = this.tab.title || this.screen.name;
        this.tab.name = this.$utils.kebab(
          this.screen.name.replace(/ - /g, " ")
        );
        this.tab.contractId = this.screen.contract_id;
        let payload;
        if (this.context == "user") {
          payload = this.loggedUser.user_profile.portal_data ?? {};
        } else {
          this.tab.name = "@" + this.tab.name;
          payload = this.loggedUser.contract.portal_data ?? {};
        }
        this.tab.href += this.tab.name;
        payload.userTabs = (payload.userTabs || []).concat(this.tab);

        this.loading = true;
        this.updateService(payload).then((response) => {
          this.loading = false;
          if (this.validateSaveResponse(response)) {
            this.showAlert(() => this.$emit("update:open", false));
          } else {
            this.showAlert();
          }
        });
      }
    },
    closeIconLibrary() {
      this.iconPanelOpen = false;
    },
    init() {
      if (!this.screensLoaded) {
        this.loadingScreens = true;
        this.fetchScreens({contract_id: this.contract_id}).then(() => {
          this.loadingScreens = false;
          this.screensLoaded = true;
        });
      }
      // resets tab data
      this.tab = {
        screenId: null,
        title: "",
        iconClass: "fa fa-desktop",
        name: "",
        href: "/dashboard/search/custom/",
        component: "DashboardCustom",
        enabled: true,
        rule: "TelaCustomizadaAcesso",
        contractId: null
      };
    },
    ...mapActions("dashboard", ["fetchScreens"]),
    ...mapActions("user", {
      updateUserPortalData: "updatePortalData",
      updateContractPortalData: "updateContractPortalData"
    })
  }
};
</script>

<style lang="scss" scoped>
.fields {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.5em 0;

  & > * {
    flex: 1;
    margin-bottom: 0;

    &:not(:last-of-type) {
      margin-right: 1em;
    }
  }

  .no-screens {
    border: 1px solid #ccc;
    transition: border-color ease-in-out 0.15s;
    color: #555;
    padding-left: 10px;

    &:focus {
      border-color: #dd4b39;
    }
  }
}
</style>
